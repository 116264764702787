var galleryCreated = false;

export default function () {
	$('.js-carousel-home').slick({
		dots: false,
		infinite: true,
		slidesToShow: 2,
		slide: '.js-slide-home',
		prevArrow: '.js-slide-left',
		nextArrow: '.js-slide-right',
		responsive: [
	    {
	      breakpoint: 767,
	      settings: {
	        slidesToShow: 1
	      }
	    }]
	})

	$('.js-property-carousel').slick({
		dots: false,
		infinite: true,
		slidesToShow: 1,
		slide: '.js-carousel-photo',
		prevArrow: '.js-slide-left',
		nextArrow: '.js-slide-right',
		asNavFor: '.js-property-thumbnails'
	})

	$('.js-property-thumbnails').slick({
		dots: false,
		arrows: false,
		slidesToShow: 9,
		centerMode: false,
  		focusOnSelect: true,
		asNavFor: '.js-property-carousel',
		slide: '.js-thumbnail'
	})

	$('.js-property-carousel-header').slick({
		dots: false,
		infinite: true,
		slidesToShow: 1,
		slide: '.js-carousel-header-photo',
		prevArrow: '.js-slide-left.header-carousel',
		nextArrow: '.js-slide-right.header-carousel',
		responsive: [
			{
				breakpoint: 768,
				settings: {
					centerMode: false,
					focusOnSelect: false,
				}
			}
		]
	})

	$('.js-basic-slideshow').slick({
		slidesToShow: 1,
		infinite: true,
		dots: false,
		slide: '.js-basic-slide',
		prevArrow: '.js-basic-slide-left',
		nextArrow: '.js-basic-slide-right'
	})

	//arrows for single-inscription



	function prevNext(){
		var prev = $('.left.header-carousel');
		var next = $('.right.header-carousel');
		var currentSlide = $('.c-banner-slick .slick-active');
		var currentIndex = currentSlide.data('slick-index');
		var imgPrev = $('.js-carousel-header-photo[data-slick-index="' + (currentIndex - 1) + '"]').css('background-image');
		var imgNext = $('.js-carousel-header-photo[data-slick-index="' + (currentIndex + 1) + '"]').css('background-image');

		$('.c-banner-slick button.left span').css('background-image', imgPrev);
		$('.c-banner-slick button.right span').css('background-image', imgNext);

	}

	prevNext();

	$('.c-banner-slick button').on('click', function(){
		prevNext();
	});

	setTimeout(function() {
		$('.c-market-news__arrow').addClass('animation-is-over');
	}, 3100)
}