import ScrollMagic from 'scrollmagic';
import 'potato'; // Using package.json browse function

// Controller
let controller;

// Timelines
let timelineIntro;
let timelineBackdrop;
let timelineCta1;
let timelineCta2;
let timelineCta3;
let timelineBubble1;
let timelineBubble2;
    
// Scenes
let sceneIntro;
let sceneBackdrop;
let sceneCtas;
let sceneBubble1;
let sceneBubble2;

export default function () {

	if ( document.querySelector('[data-parallax]') != null ) {
		initParallax();
	}

	function initParallax() {
		setController();
		setTimelines();
		setScenes();
	}

    function setController() {
    	controller = new ScrollMagic.Controller({
	        globalSceneOptions: {
	            triggerHook: 'onEnter'
	        }
	    });
    }

    function setTimelines() {
    	timelineIntro = new TimelineMax()
	        .add([
	            TweenMax.to('.js-parallax-element', 1, {opacity: '0', delay: 0}),
	            TweenMax.to('.js-parallax-element', 1, {transform: 'translate(0, 200px)', delay: 0})
	        ]);

	    timelineBackdrop = new TimelineMax()
	        .add([
	            TweenMax.to('.js-parallax-backdrop', 1, {opacity: '1', delay: 0})
	        ]); 

	    timelineCta1 = new TimelineMax()
	        .add([
	            TweenMax.to('.js-cta-1', 1, {transform: 'translate(0, 0)', delay: 0}),
	            TweenMax.to('.js-cta-1', 1, {opacity: '1', delay: 0})
	        ]);
	    timelineCta2 = new TimelineMax()
	        .add([
	            TweenMax.to('.js-cta-2', 1, {transform: 'translate(0, 0)', delay: 0}),
	            TweenMax.to('.js-cta-2', 1, {opacity: '1', delay: 0})
	        ]); 
	    timelineCta3 = new TimelineMax()
	        .add([
	            TweenMax.to('.js-cta-3', 1, {transform: 'translate(0, 0)', delay: 0}),
	            TweenMax.to('.js-cta-3', 1, {opacity: '1', delay: 0})
	        ]);        

	    timelineBubble1 = new TimelineMax()
	        .add([
	            TweenMax.to('.js-parallax-bubble-1', 1, {transform: 'translate(0, 0)', delay: 0})
	        ]); 
	    timelineBubble2 = new TimelineMax()
	        .add([
	            TweenMax.to('.js-parallax-bubble-2', 1, {transform: 'translate(0, 0)', delay: 0.3})
	        ]);             
    }

    function setScenes() {
    	// Banner text
	    sceneIntro = new ScrollMagic.Scene({
	        duration: 1000,
	        offset: 0,
	        triggerElement: '.js-parallax-trigger-banner'
	    })
	    .setTween(timelineIntro)
	    .addTo(controller);

	    // Banner backdrop
	    sceneBackdrop = new ScrollMagic.Scene({
	        duration: 500,
	        offset: 0,
	        triggerElement: '.js-parallax-trigger-banner'
	    })
	    .setTween(timelineBackdrop)
	    .addTo(controller);

	    // Banner cta 1
	    sceneCtas = new ScrollMagic.Scene({
	        duration: 800,
	        offset: 0,
	        triggerElement: '.js-parallax-trigger-banner'
	    })
	    .setTween(timelineCta1)
	    .addTo(controller);

	    // Banner cta 2
	    sceneCtas = new ScrollMagic.Scene({
	        duration: 800,
	        offset: 0,
	        triggerElement: '.js-parallax-trigger-banner'
	    })
	    .setTween(timelineCta2)
	    .addTo(controller);

	    // Banner cta 3
	    sceneCtas = new ScrollMagic.Scene({
	        duration: 800,
	        offset: 0,
	        triggerElement: '.js-parallax-trigger-banner'
	    })
	    .setTween(timelineCta3)
	    .addTo(controller);

	    // Bubble 1
	    sceneBubble1 = new ScrollMagic.Scene({
	        duration: 500,
	        offset: 500,
	        triggerElement: '.js-parallax-trigger-bubbles'
	    })
	    .setTween(timelineBubble1)
	    .addTo(controller);

	    // Bubble 2
	    sceneBubble1 = new ScrollMagic.Scene({
	        duration: 600,
	        offset: 550,
	        triggerElement: '.js-parallax-trigger-bubbles'
	    })
	    .setTween(timelineBubble2)
	    .addTo(controller);
    }

}