import $ from 'jquery';

export default function () {

	$('.js-open-modal').click(function(){
		$('body').addClass('modal-open');
	});


	$('.js-close-video').click(function(){
		$('body').removeClass('modal-open');
	});

}