import $ from 'jquery';
import noUiSlider from 'nouislider';

let resetButton = document.querySelector('.js-reset-form');

export default function () {

	if ( resetButton ) {
		resetButton.addEventListener('click', resetSlider);
	}

	var slider = document.querySelector('.js-drag-search'),
		min = 0,
		max = 1500000,
		start_min = 0,
		start_max = 1500000,
		step = 5000;
	
	if ( slider != null ) {
		noUiSlider.create(slider, {
			start: [start_min, start_max],
			connect: true,
			range: {
				'min': min,
				'max': max
			},
			step: step
		});
		
		slider.noUiSlider.on('update', updateValues );

	}

	function updateValues ( values, handle, unencoded, tap, positions ) {

		var left_handle = unencoded[0];
		var right_handle = unencoded[1];
		var infinite_string = "";

		if(ICL_LANGUAGE_CODE == "fr") {
			infinite_string = " Illimité";
		} else {
			infinite_string = " Unlimited";
		}


		$(slider).attr('data-price-min', left_handle);
		$(slider).attr('data-price-max', right_handle);
		$('.js-min').text(formatPrice(left_handle) + ' $');

		if(right_handle >= max) {
			$('.js-max').text(infinite_string);
		} else {
			$('.js-max').text(formatPrice(right_handle) + ' $');
		}


	}

	function formatPrice ( price) {

		// Plugin returns weird values from time to time, so make sure to round it up
		price = Math.round(price);
		// Format numbers so it's readable for users
		price = price.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

		return price;

	}

	function resetSlider () {
		if ( slider != null ) {
			slider.noUiSlider.set([start_min, start_max]);
		}
	}

	function isCondo () {
		if ( slider != null ) {
			slider.noUiSlider.set([start_min, start_max]);
		}
	}


}

