import $ from 'jquery';

export default function () {

	$('.js-cta-btn').click(function(event){

		event.preventDefault();

		var $target = $('.'+ $(this).attr('data-target'));

		$('.js-cta-btn').removeClass('active');
		$(this).addClass('active');

		if(!$target.is(':visible')){

			$target.fadeIn(1000);
			$('.js-home-ctas').not($target).hide();
		}

	});

}