import $ from 'jquery';

export default function () {

    $('.js-search-listings').on('click', (event) => {
        event.preventDefault();

        // Get the inputs from the custom search form
        var type = $('.js-search-type').find('.dk-option-selected:not(.dk-option-disabled)').attr('data-value'),
            city = $('.js-search-city').find('.dk-option-selected:not(.dk-option-disabled)').attr('data-value'),
            price_min = $('.js-drag-search').attr('data-price-min'),
            price_max = $('.js-drag-search').attr('data-price-max'),
            nb_rooms = $('.js-search-nb-rooms').find('.dk-option-selected:not(.dk-option-disabled)').attr('data-value'),
            nb_bathrooms = $('.js-search-nb-bathrooms').find('.dk-option-selected:not(.dk-option-disabled)').attr('data-value'),
            url = listings_url + '?action=search';

        // Build the URL parameters String
        if(type) url += "&type=" + type;
        if(city) url += "&city=" + city;
        if(price_min) url += "&price_min=" + price_min;
        if(price_max) url += "&price_max=" + price_max;
        if(nb_rooms) url += "&nb_rooms=" + nb_rooms;
        if(nb_bathrooms) url += "&nb_bathrooms=" + nb_bathrooms;

        // Redirect to the listing page
        window.location.replace(url);

    });

    $('.js-filter-by').on('change', (event) => {

        // Get the order by value from the dropdown
        var order_by = $('.js-filter-by').find('.dk-option-selected:not(.dk-option-disabled)').attr('data-value');
        // Get the current URL and make sure to strip the previous order by option
        var current_url = removeURLParameter(window.location.href, 'order_by');
        // Append the new Order By
        var current_params = decodeURIComponent(window.location.search.substring(1));


        if(current_params!="" & current_params.indexOf('order_by')) {
            var new_url = current_url += "&order_by=" + order_by;
        } else {
            var new_url = current_url += "?order_by=" + order_by;
        }

        window.location.replace(new_url);

    });
}

function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts= url.split('?');
    if (urlparts.length>=2) {

        var prefix= encodeURIComponent(parameter)+'=';
        var pars= urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i= pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url= urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
        return url;
    } else {
        return url;
    }
}
