import $ from 'jquery';

export default function () {

	$('.has-submenu').mouseenter(function(){
		$(this).addClass('submenu-active');
	})

	$('.has-submenu').mouseleave(function(){
		var $currentElement = $(this);
		var timeoutValue = 300;

		if ( $('.has-submenu:hover').length != 0 )
			timeoutValue = 0;
			
		setTimeout(function(){
			removeSubmenu( $currentElement );
		}, timeoutValue)
	})

	$('.has-mobile-submenu').click(function(){
		$(this).toggleClass('subnav-opened');
	})

	var removeSubmenu = function($el) {
		if ( !$el.is(':hover') ) {
			$el.removeClass('submenu-active');
		}
	}
}
