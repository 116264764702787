import $ from 'jquery';
export default function () {

    $('.js-openpane').click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        $('.c-formulaire-rendez-vous').addClass('active');
        $('body').css('overflow', 'hidden');
    })



    $('.close-btn').click(function() {
        $('.c-formulaire-rendez-vous').removeClass('active');
        $('body').css('overflow', 'visible');
    })

    $('.c-formulaire-rendez-vous .wrapper').click(function(e) {
        e.stopPropagation();
    })



    $(document).keyup(function(e) {
        if (e.keyCode == 27) {
            $('.c-formulaire-rendez-vous').removeClass('active');
        }
    });

    $('body').click(function() {
        $('.c-formulaire-rendez-vous').removeClass('active');
        $('body').css('overflow', 'visible');
    })

}