export default function () {

	if($('.js-sold-counter').length) {

		var $field = $('.js-sold-counter');
		var max_value = $field.attr('data-max-value');


		$({someValue: 0}).animate({someValue: max_value}, {
			duration: 1200,
			easing:'swing', // can be anything
			step: function() { // called on every step
				// Update the element's text with rounded-up value:
				$field.text(Math.ceil(this.someValue));
			},
			complete: function() {
				$field.addClass('animate')
				$field.text(max_value);
			}
		});
	}
}