export default function () {

	let tab;

	$('.c-tabs li').on('click', function(){
		$('.c-tabs .active').removeClass('active');
		$(this).addClass('active');
		tab = $(this).find('a').data('tab');

		$('.c-tabs__panel.active').removeClass('active');
		$('.c-tabs__panel#' + tab).addClass('active');
	});

	$('.js-active-tabs li:first-of-type').addClass('active');
	$('.js-active-tabs-content .c-tabs__panel:first-of-type').addClass('active');


	$('.c-tabs__button[data-tab="gallery"]').on('click', function() {
		$('#gallery').resize();
	})
}