import $ from 'jquery';

export default function() {
    var ref = getParameterByName('ref');
    if(ref != null) {
        var date = new Date();
        localStorage.setItem( 'refDate', date );
        localStorage.setItem( 'ref', ref );
    }
}
function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
