import $ from 'jquery';

export default function () {

	if($('.js-calcultator').length) {
		updatePrices();
	}

	$('.js-payments-btn').on('click', updatePrices);

}

function updatePrices() {

	var $montant = $('.js-calculator-montant'),
		montant = parseFloat($('.js-calculator-montant').val()),
		$interet = $('.js-calculator-interets'),
		interet = parseFloat($('.js-calculator-interets').val()),
		$duree = $('.js-calculator-duree'),
		duree = parseFloat($('.js-calculator-duree').val()),
		$acompte = $('.js-calculator-acompte'),
		acompte = parseFloat($('.js-calculator-acompte').val()),
		hasErrors = false;

	if( isNaN(montant) || montant <= 0 ) {
		$montant.addClass('has-error');
		hasErrors = true;
	}
	if( isNaN(interet) || interet <= 0 ) {
		$interet.addClass('has-error');
		hasErrors = true;
	}
	if( isNaN(duree) || duree <= 0 ) {
		$duree.addClass('has-error');
		hasErrors = true;
	}
	if( isNaN(acompte) || acompte < 0 || acompte > 100) {
		$acompte.addClass('has-error');
		hasErrors = true;
	}

	if(hasErrors) {

		$('.js-calculator-montant-acompte').html(`0 <span>$</span>`)
		$('.js-calculator-payments').html(`0 <span>$</span>`)

	} else {

		$('*[class^="js-calculator"]').removeClass('has-error');

		acompte = montant * (acompte/100);
		montant = montant - acompte;
		interet = (interet/100) / 12;

		var payments = (montant*interet)/(1-(1/(Math.pow(1+interet,(12*duree)))));

		$('.js-calculator-montant-acompte').html(`${formatPrice(acompte)} <span>$</span>`)
		$('.js-calculator-payments').html(`${formatPrice(payments)} <span>$</span>`)

	}

}

function formatPrice( price ) {

	// Plugin returns weird values from time to time, so make sure to round it up
	price = Math.round(price);
	// Format numbers so it's readable for users
	price = price.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

	return price;

}