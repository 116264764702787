import $ from 'jquery';
import FormValidator from './formValidator';
import { prospectsWebKey } from '../constants';
export default function () {
    var $form = $('.fn-prospects-lead-grabber');
    if($form.length) {
        var prospectsWebKey = $('#prospects-web-token').val();
        if(!prospectsWebKey || prospectsWebKey == "") {
            //To form token was specified so don't display the form
            $form.closest('.c-sidebar').remove();
        } else {
            // Generate the keys and actions
            var keys = generateHdsToken(decodeURIComponent(prospectsWebKey));
            // If Prospect returns a valid HDS Token, init the form to display it !
            if(keys.hds){
                initLeadGrabberForm(keys);
            };
        }
    }
}
/*******************************************/
/*** Generate UHD Token avec form action ***/
/*******************************************/
function generateHdsToken(token) {
    var keys = {};
    $.ajax(
        {
        "type": "GET",
        "async": false,
        "dataType": "html",
        "url": "https://www.prospectsweb.com/prospects/leadgrabber.do",
        "data": {
            "tok": token,
            "type": "1",
            "lang": "fr",
        },
        "success": function(jData, status, jqXHR) {
            keys.action = $(jData).find('form').attr('action');
            keys.hds = $(jData).find('input[name="hds"]').val();
        },
        "error": function() {
            $('.fn-prospects-lead-grabber-form').closest('.c-sidebar').remove();
        },
    });
    return keys;
}
/*******************************************/
/*** Generate UHD Token avec form action ***/
/*******************************************/
function initLeadGrabberForm(keys) {
    if (ICL_LANGUAGE_CODE == 'en') {
        $('.fn-prospects-lead-grabber-form .person-info').append(`
            <div class="c-sidebar__group">
                <input class="required" name="contact.prenom" type="text" value="" placeholder="First Name">
                <input class="required" name="contact.nom" type="text" value="" placeholder="Last Name">
                <input class="required" name="contact.emailIndexed[0]" type="text" value="" placeholder="Email">
                <input class="" name="contact.telRes" type="text" value="" placeholder="Phone">
            </div>
        `);
    } else {
        $('.fn-prospects-lead-grabber-form .person-info').append(`
            <div class="c-sidebar__group">
                <input class="required" name="contact.prenom" type="text" value="" placeholder="Prenom">
                <input class="required" name="contact.nom" type="text" value="" placeholder="Nom">
                <input class="required" name="contact.emailIndexed[0]" type="text" value="" placeholder="Courriel">
                <input class="" name="contact.telRes" type="text" value="" placeholder="Téléphone">
            </div>
        `);
    }

    $('.fn-prospects-lead-grabber-form button').on('click', function(event){
        $(this).append('<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>');
        event.preventDefault();
        if(validateUserInput()){
            submitForm(keys);
        } else {
            $(this).find('.fa-spinner').remove();
        }
    });
}
/*************************************************************************/
/*** Used to validate the user input. Returns true is tests are passed ***/
/*************************************************************************/
function validateUserInput() {
    var formValidator = new FormValidator(),
        $form = $('.fn-prospects-lead-grabber-form'),
        $email = $form.find('input[name="contact.emailIndexed[0]"]'),
        $custom_inputs = $form.find('.fn-prospects-lead-grabber-custom-field');
    // Validate that required fields have a value
    if(
        formValidator.validateRequired($form.find('.required')) &&
        formValidator.validateEmail($email))
    {
        return true;
    } else {
        return false;
    }
}
/**********************************************************************************/
/*** Function to submit the contact form once the user input has been validated ***/
/**********************************************************************************/
function submitForm(keys) {
    var $form = $('.fn-prospects-lead-grabber-form'),
        $prenom = $form.find('input[name="contact.prenom"]'),
        $nom = $form.find('input[name="contact.nom"]'),
        $email = $form.find('input[name="contact.emailIndexed[0]"]'),
        $tel = $form.find('input[name="contact.telRes"]'),
        $custom_inputs = $form.find('.fn-prospects-lead-grabber-custom-field'),
        refeer = null,
        notes = "Formulaire Lead Groupelavoie.com\n";
    var urlRef = localStorage.getItem('refDate');
    if(urlRef) {
        if((Math.abs(new Date() - new Date(localStorage.getItem('refDate'))) / 36e5) <= 1) {
            refeer = localStorage.getItem('ref')
        } else {
            // Session var too old
        }
    }
    // If a custom field is entered or the page has a refeer, prepend it with date/time
    if($custom_inputs.length > 1 || refeer) {
        if(refeer!="" && refeer) {
            notes += 'Provenance: ' + refeer + '\n';
        }
    }
    if(pageSlug == '230') {
        console.log('Add note for Devenir Courtier')
        notes += 'Page devenir courtier\n';
    }
    // Concat all custom fields in notes
    $custom_inputs.each(function(){
        if($(this).attr('type')=='checkbox') {
            let value = '';
            if($(this).is(':checked')) {
                value = 'Oui';
            } else {
                value = 'Non';
            }
            notes += $(this).attr('data-prospect-label') + ': ' + value + '\n';
        } else {
            notes += $(this).attr('data-prospect-label') + ': ' + $(this).val() + '\n';
        }
    });
    // Send the ajax call
    $.ajax(
        {
        "type": "POST",
        "dataType": "text",
        "url": "https://www.prospectsweb.com" + keys.action,
        "data": {
            "lang": "en",
            "hds": keys.hds,
            "dispatch": "lcb",
            "clearMun": "",
            "contact.prenom": $prenom.val(),
            "contact.nom": $nom.val(),
            "contact.emailIndexed[0]": $email.val(),
            "contact.telRes": $tel.val(),
            "notes": notes,
        },
        complete: function() {
            $('.fn-prospects-lead-grabber-form').slideUp(300);
            $('.fn-prospects-lead-grabber-form__success').fadeIn(300);

            if($('body').hasClass('parent-pageid-9')) {
                window.location.replace("http://gl-pre.lefacteurc.ca/confirmation-acheteur");
            }

            if($('body').hasClass('parent-pageid-23')) {
                window.location.replace("http://gl-pre.lefacteurc.ca/confirmation-vendeur");
            }
        }
    });
}
