import $ from 'jquery';
import { mapStyles } from '../constants';

export default function () {

	if($('.js-home-eval').length) {
		$('body').addClass('no-scroll')
	}

 	if($('#js-home-eval-results').length) {

 		//display the search results
 		initResultsMap();

 	} else if ($("#js-user-address").length) {

 		//init the search form
 		initSearchForm();
 	}
}

function initResultsMap() {

	var lat = getUrlParameter('lat'),
		lng = getUrlParameter('lng');

	if(lat && lng) {

		var latlng = new google.maps.LatLng(lat, lng);

	 	// Create a map object and specify the DOM element for display.
		var map = new google.maps.Map(document.getElementById('js-home-eval-results'), {
			center: latlng,
			scrollwheel: false,
			styles: mapStyles,
			zoom: 15
		});

		 var icon = {
          url: assets + "images/c-home-eval-map-icon.png",
          // This marker is 20 pixels wide by 32 pixels high.
          scaledSize: new google.maps.Size(316, 215),
          // The origin for this image is (0, 0).
          origin: new google.maps.Point(0, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
          anchor: new google.maps.Point(158, 192)
        };

        setTimeout(function(){

        	var marker = new google.maps.Marker({
				map: map,
				position: latlng,
				icon: icon,
				animation: google.maps.Animation.DROP,
			});

        }, 1500);

		

	}

}

function initSearchForm() {


	var input = document.getElementById('js-user-address');

	var options = {
  		componentRestrictions: {country: "ca"}
 	};
 	
	var autocomplete = new google.maps.places.Autocomplete(input, options);
        //autocomplete.bindTo('bounds', map);

	$('#js-submit-homeeval').click(function(event){
		event.preventDefault();

		$('#js-homeeval-error').removeClass('visible');

		if( autocomplete.getPlace() &&
			autocomplete.getPlace()
		) {

			$('#js-homeeval-error').fadeOut(300);

			var lat = autocomplete.getPlace().geometry.location.lat();
			var lng = autocomplete.getPlace().geometry.location.lng();

			$('#js-user-lat').val(lat);
			$('#js-user-lng').val(lng);

			$('#js-homeeval-form').submit();

		} else {

			$('#js-homeeval-error').addClass('visible');

		}

	})

}

function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};