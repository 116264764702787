import $ from 'jquery';

class FormValidator {

	constructor() {
	}

	validateRequired(inputs) {

		var has_errors = false;

		inputs.each(function(){

			if($(this).val()=="") {
				$(this).addClass('has_error')
				has_errors = true;
			} else {
				$(this).removeClass('has_error')
			}

		});

		if(has_errors) {
			return false;
		} else {
			return true;
		}
	}

	validateEmail(inputs) {

		var has_errors = false;

		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		inputs.each(function(){

			if(!re.test($(this).val())) {
				$(this).addClass('has_error')
				has_errors = true;
			} else {
				$(this).removeClass('has_error')
			}

		});

		if(has_errors) {
			return false;
		} else {
			return true;
		}

	}

}

export default FormValidator;

// function validateEmail(email) {
//     var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     return re.test(email);
// }