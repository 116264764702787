import $ from 'jquery';

export default function () {
	
	var $buttons = $('.js-library-filters a[data-category]');
	
	$buttons.click(function(event){
		event.preventDefault();
		$buttons.removeClass('active');
		$(this).addClass('active');

		var category = $(this).attr('data-category');

		if(category=="")
		{
			$(`div[data-category]`).hide();
			$(`div[data-category]`).fadeIn(1000);
		}
		else
		{
			$(`div[data-category]`).hide();
			$(`div[data-category="${category}"]`).fadeIn(1000);

		}

	});
}
