import $ from 'jquery';

export default function () {

	var propertyBar = document.querySelector('.js-property-bar');
	
	if ( propertyBar != null ) {
		scrollListener();
	}
}

function scrollListener() {

	var barPosition = $('.js-property-bar').offset().top;
	var barFixed = false;

	$(window).on('scroll', function(){
		//console.log('123');

		// Bar becomes fixed
		if ( $(window).scrollTop() >= barPosition && barFixed == false ) {
			$('body').addClass('property-bar-fixed');
			barFixed = true;
		}

		// Bar no longer fixed
		if ( $(window).scrollTop() < barPosition && barFixed == true ) {
			$('body').removeClass('property-bar-fixed');
			barFixed = false;
		}
	
	})
}
