import $ from 'jquery';

export default function () {

    $('#toggle').click(function(e) {
		e.stopPropagation();
		e.preventDefault();
		
    	$('body').toggleClass('mobile-on');
		document.addEventListener('click', bodyClickFn);
		document.addEventListener('touchstart', bodyClickFn);
	});
	
	var resetMenu = function() {
		$('body').removeClass('mobile-on');
	};

	var bodyClickFn = function(e) {

		if( !hasParentClass( e.target, 'c-mobile-nav' ) ) {
			resetMenu();
			document.removeEventListener('click', bodyClickFn);
			document.removeEventListener('touchstart', bodyClickFn);
		}
	};

	var hasParentClass = function(e, classname){
		if(e === document) return false;
		if($(e).hasClass(classname)){
			return true;
		}
		return e.parentNode && hasParentClass(e.parentNode, classname);
	}

}
