import $ from 'jquery';
import { mapStyles } from '../constants';

export { initiateGoogleMap }

function initiateGoogleMap() {
	var map = document.querySelector('#map');
	var property_map = document.querySelector('#property_map');
	
	if ( map != null ) {
		createMap();
	}

	if ( property_map != null ) {
		createPropertyMap();
	}
}

function createMap() {

	var myLatLng = {lat: 45.390355, lng: -73.580296};
	var theMarker = {lat: 45.390355, lng: -73.580296};

	// Create a map object and specify the DOM element for display.
	var map = new google.maps.Map(document.getElementById('map'), {
		center: myLatLng,
		scrollwheel: false,
		styles: mapStyles,
		zoom: 15
	});

	//Create a marker and set its position.
	// var marker1 = new google.maps.Marker({
	// 	map: map,
	// 	position: theMarker,
	// 	icon: assets + "images/map-icon.png"
	// });	
}

function createPropertyMap() {

	//$('.c-tabs__button[href="#maps"]').click(function(){
		console.log('123');

		var theMarker = {lat: parseFloat($('#property_map').attr('data-lat')), lng: parseFloat($('#property_map').attr('data-lng')) };

		// Create a map object and specify the DOM element for display.
		var map = new google.maps.Map(document.getElementById('property_map'), {
			center: theMarker,
			scrollwheel: false,
			zoom: 15
		});

		var marker1 = new google.maps.Marker({
			map: map,
			position: theMarker,
		});

	//});

}