// Setup
import './modules/helpers';
import start from './modules/router';

// Functions
import accordeons from './modules/accordeons';
import mobileNav from './modules/mobileNav';
import subNav from './modules/subNav';
import sideSearch from './modules/sideSearch';
import carousel from './modules/carousel';
import wow from './modules/wow';
import drag from './modules/drag-slider';
import dropdowns from './modules/dropdowns';
/*import tabs from './modules/tabs';*/
import propertyBar from './modules/propertyBar';
import LibraryFilters from './modules/library-filters';
import NewsFilters from './modules/news-filters';
import home from './modules/home';
import listingsSearch from './modules/listingsSearch';
import { initiateGoogleMap } from './modules/map';
import youtube from './modules/youtube';
import pageLoader from './modules/pageLoader';
import homeEval from './modules/homeEval';
import soldCounter from './modules/soldCounter';
import scroller from './modules/scroller';
import share from './modules/share';
import referal from './modules/referal';
import leadGrabber from './modules/leadGrabber';
import lbPane from './modules/lbPane';
import lightbox from './modules/lightbox';
import tab from './modules/tab'

// new
import parallax from './modules/parallax';
import simpleToggle from './modules/simpleToggle';

// Classes
import paymentsCalculator from './modules/paymentsCalculator';

start();
accordeons();
mobileNav();
subNav();
//tabs();
tab();
dropdowns();
carousel();
sideSearch();
drag();
propertyBar();
wow();
LibraryFilters();
NewsFilters();
initiateGoogleMap(); // Async loading
youtube();
home();
listingsSearch();
paymentsCalculator();
pageLoader();
homeEval();
soldCounter();
scroller();
share();
referal();
leadGrabber();
// new
parallax();
simpleToggle();
lbPane();
lightbox();
