import $ from 'jquery';

export default function () {

    $('.js-accordeon').click(function(){

        if(!$(this).hasClass('active')) {
            $('.js-accordeon').not($(this)).removeClass('active');
        }

        $(this).toggleClass('active');
    });
}
