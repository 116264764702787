import $ from 'jquery';
import Dropkick from 'dropkickjs';
import Cookies from './cookiesManager';

// dropdowns
let dropdowns = document.querySelectorAll('.js-dropdown');
let arrayDropdowns = [];

// reset button
let resetButton = document.querySelector('.js-reset-form');

// open / close
let openFormButton = document.querySelector('.js-open-advanced');
let closeFormButton = document.querySelector('.js-close-form');

export default function () {
	addListeners();
}

function addListeners() {

	var formCookie = Cookies.get('closedForm');
	
	if ( formCookie != undefined ) {
		$('.js-advanced-form').addClass('closed');
	}

	for ( let i = 0; i < dropdowns.length; i++ ) {
		arrayDropdowns.push(new Dropkick(dropdowns[i]));
	}

	// if ( resetButton ) {
	// 	resetButton.addEventListener('click', resetForm);
	// }

	if ( openFormButton ) {

		

		$(openFormButton).click(function(e){

			formCookie = Cookies.get('closedForm');

			if(!formCookie){
				console.info('close');
				e.preventDefault();
				$('.js-advanced-form').addClass('closed');
				Cookies.set('closedForm', '1', { expires: 14 });
			} else {
				console.info('open');
				e.preventDefault();
				$('.js-advanced-form').removeClass('closed');
				Cookies.remove('closedForm');
			}

		});

	
	}

	if ( closeFormButton ) {
		$(closeFormButton).click(function(e){
			
			console.info('close');
			e.preventDefault();
			$('.js-advanced-form').addClass('closed');
			Cookies.set('closedForm', '1', { expires: 14 });
			
		})
	}
	
}

// function resetForm() {
// 	for ( let i = 0; i < dropdowns.length; i++ ) {
// 		arrayDropdowns[i].reset(true);
// 	}
// }