import $ from 'jquery';

export default function () {
	
	var $show_all = $('.js-news-category[data-category="all"]');
	var $buttons = $('.js-news-category').not($show_all);
	var paramCategory = $('.js-load-category').attr('data-category');
	$('.js-no-result').hide();

	if(paramCategory) {
		$('.js-blog-post').hide();
		$show_all.removeClass('active');
		$('.js-blog-post.cat-'+paramCategory).fadeIn(1000);
		$(`.js-news-category[data-category="${paramCategory}"]`).addClass('active');
	}

	$buttons.click(function(event){

		event.preventDefault();

		$(this).toggleClass('active');

		if($buttons.hasClass('active')){
			$show_all.removeClass('active');
		} else {
			$show_all.addClass('active');
		}

		var categories = "";

		$('.js-news-category.active').not($show_all).each(function(){
			categories += '.cat-' + $(this).attr('data-category');
		});

		$('.js-blog-post').hide();
		$('.js-blog-post'+categories).fadeIn(1000);


		if($('.js-blog-post:visible').length > 0) {
			$('.js-no-result').hide();
		} else {
			$('.js-no-result').show();
		}

	});

	$show_all.click(function(event){

		event.preventDefault();

		$(this).addClass('active');
		$buttons.removeClass('active');

		$('.js-blog-post').hide();
		$('.js-blog-post').fadeIn(1000);

	});

}